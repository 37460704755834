import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import SchoolIcon from "@mui/icons-material/School";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./main-menu.module.scss";
import InstagramIcon from "../../../../assets/icons/instagram.svg";
import TikTokIcon from "../../../../assets/icons/tiktok.svg";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";

type TemporaryDrawerProps = {
  isOpen: boolean;
  toggle: () => void;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function TemporaryDrawer({
  isOpen,
  toggle,
  setDrawerOpen,
}: TemporaryDrawerProps) {
  const [state, setState] = React.useState({
    left: false,
  });
  const navigate = useNavigate(); // <-- use the hook

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, left: open });
    };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      //onClick={setDrawerOpen(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "8px",
        }}
      >
        <CloseIcon
          className={styles.closeIcon}
          onClick={() => setDrawerOpen(false)}
        />
      </div>
      <List className={styles.whiteText}>
        <ListItem key="About" disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("/about-us");
              setDrawerOpen(false);
            }}
          >
            <ListItemIcon>
              <AutoAwesomeIcon style={{ color: "#9A82DE" }} />
            </ListItemIcon>
            <ListItemText primary="Про lasno." />
          </ListItemButton>
        </ListItem>
        <ListItem key="Stories" disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("/search-content");
              setDrawerOpen(false);
            }}
          >
            <ListItemIcon>
              <LocalFireDepartmentIcon style={{ color: "#9A82DE" }} />
            </ListItemIcon>
            <ListItemText primary="Еротичні історії" />
          </ListItemButton>
        </ListItem>
        <ListItem key="Sex Education" disablePadding>
          <ListItemButton
            onClick={() => {
              setDrawerOpen(false);
            }}
          >
            <ListItemIcon>
              <SchoolIcon style={{ color: "#9A82DE" }} />
            </ListItemIcon>
            <ListItemText primary="Sex просвіта" />
          </ListItemButton>
          <Chip
            label="Незабаром"
            variant="outlined"
            size="small"
            sx={{
              color: "#9a82de",
              "& .MuiChip-root": {
                margin: "3px",
              },
            }}
          />
        </ListItem>
      </List>

      <Divider className={styles.gradientDivider} />
      <List className={styles.whiteText}>
        <ListItem key="Subscription" disablePadding>
          <ListItemButton
            onClick={() => {
              setDrawerOpen(false);
            }}
          >
            <ListItemIcon>
              <LoyaltyIcon style={{ color: "#9A82DE" }} />
            </ListItemIcon>
            <ListItemText primary="Підписка" />
          </ListItemButton>
        </ListItem>
        <ListItem key="Support" disablePadding>
          <ListItemButton
            onClick={() => {
              setDrawerOpen(false);
            }}
          >
            <ListItemIcon>
              <SupportAgentIcon style={{ color: "#9A82DE" }} />
            </ListItemIcon>
            <ListItemText primary="Підтримка" />
          </ListItemButton>
        </ListItem>
      </List>

      <div className={styles.socialMediaContainer}>
        <a
          href="https://www.instagram.com/lasno_ua/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.iconLink}
        >
          <img
            src={InstagramIcon}
            alt="Instagram"
            className={styles.socialMediaIcon}
          />
        </a>
        <a
          href="https://www.tiktok.com/@lasno.world"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.iconLink}
        >
          <img
            src={TikTokIcon}
            alt="TikTok"
            className={styles.socialMediaIcon}
          />
        </a>
      </div>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="left">
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={toggle}
          PaperProps={{
            className: styles.drawerBackground,
          }}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
