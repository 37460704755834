import React from "react";
import Logo from "../../components/logo/logo"; // Importing the Logo component
import styles from "./about-us.module.scss";
import StoryView from "../../../story-view/story-view";
import LoveYourselfIcon from "../../../../assets/icons/love-yourself.svg";
import DancingPeopleIcon from "../../../../assets/icons/dancing-people.svg";
import { useNavigate } from "react-router-dom";

const AboutUsPage: React.FC = () => {
  const navigate = useNavigate(); // <-- use the hook

  const handleButtonClick = () => {
    navigate("/search-content"); // <-- navigate to the desired path
  };

  return (
    <div className={styles.container}>
      <Logo /> {/* Rendering the Logo component */}
      <div className={styles.motto}>Кохаємось українською</div>
      <div className={styles.description}>
        Розкриваймо свою сексуальність без сорому та обмежень
      </div>
      <div className={styles.title}>Про lasno.</div>
      <div className={styles.content}>
        Твоя насолода не випадковість і не розкіш. Ми створюємо lasno для людей,
        які хочуть відтворити контакт зі своїм тілом і сексуальними бажаннями.
      </div>
      <button className={styles.moreContentBtn} onClick={handleButtonClick}>
        Хочу ласного контенту
      </button>
      <div className={styles.title}>Наші історії</div>
      <div className={styles.content}>
        Хтивість на повну. Ми, як і наші сексуальні бажання, різні і це -
        нормально
        <br />
        Включають активну згоду. Тільки “так” означає “так”
        <br />
        Етичні. Митці фантазій отримують компенсацію за наші еротичні подорожі.
      </div>
      <div className={styles.title}>Ми хочемо, щоб ви...</div>
      <div className={styles.iconContainer}>
        <div className={styles.iconRow}>
          <img
            src={LoveYourselfIcon}
            alt="LoveYourself"
            className={styles.icon}
          />
          <span className={styles.content}>
            ... любили себе. Регулярна мастурбація покращує твій стан здоров’я.
          </span>
        </div>
        <div className={styles.iconRow}>
          <img
            src={DancingPeopleIcon}
            alt="DancingPeople"
            className={styles.icon}
          />
          <span className={styles.content}>
            Ніжно і чуйно чи дико і хтиво? Разом з lasno ти знайдеш, що тебе
            заводить.
          </span>
        </div>
      </div>
      <StoryView
        id={10}
        title="Три бажання на день народження"
        tags={["Кунілінгус", "Збавлення", "Секс утрьох"]}
        rating={4.5}
        preview="Я не чинив опір. Як тільки пройшли дверний отвір, ми знову опинилися в обіймах один одного. Вже біля дивана вона схопилася за краї моєї футболки і задерла її в..."
      />
    </div>
  );
};

export default AboutUsPage;
