import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import StoryRating from "../../components/story-rating/story-rating";
import Chip from "@mui/material/Chip";
import ArrowBackIcon from "../../../../assets/icons/arrow-back.svg";
import styles from "./read-story.module.scss";

const ReadStoryPage: React.FC = () => {
  const { storyId } = useParams<{ storyId: string }>();
  const [story, setStory] = useState<any | null>(null);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This simulates the "go back" behavior
  };

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}stories/${storyId}`
        );
        setStory(response.data);
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchStory();
  }, [storyId]);

  if (!story) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={styles.storyContainer}>
      <button onClick={handleGoBack} className={styles.goBackButton}>
        <img src={ArrowBackIcon} alt="Go back" />
      </button>
      <div className={styles.header}>
        <h1 className={styles.title}>{story.title}</h1>
        <StoryRating rating={story.rating} />
      </div>
      <div className={styles.tagsContainer}>
        {story.tags.map((tag: string, index: number) => (
          <Chip
            key={index}
            label={tag}
            variant="outlined"
            className={styles.tag}
          />
        ))}
      </div>
      <p className={styles.content}>{story.storyBody}</p>
      <div className={styles.authorName}>{story.authorName}</div>
    </div>
  );
};

export default ReadStoryPage;
