import React from "react";
import styles from "./story-rating.module.scss";
import BonfireRating from "../../../../assets/icons/rating-bonfire.svg";

interface StoryRatingProps {
  rating: number;
}

const StoryRating: React.FC<StoryRatingProps> = ({ rating }) => {
  return (
    <div className={styles.ratingContainer}>
      <span className={styles.ratingNumber}>{rating.toFixed(1)}</span>{" "}
      <img
        src={BonfireRating}
        alt="BonfireRatingIcon"
        className={styles.ratingIcon}
      />
    </div>
  );
};

export default StoryRating;
