import React from "react";
import styles from "./story-view.module.scss";
import { useNavigate } from "react-router-dom";
import StoryRating from "../common/components/story-rating/story-rating";

interface StoryViewProps {
  id: number;
  title: string;
  tags: string[];
  rating: number;
  preview: string;
}

function formatTags(tags: string[]): string {
  if (tags.length === 0) return "";
  if (tags.length === 1) return tags[0];

  return tags.join(", ");
}

const StoryView: React.FC<StoryViewProps> = ({
  id,
  title,
  tags,
  rating,
  preview,
}) => {
  const navigate = useNavigate();

  const handleReadFullStory = () => {
    navigate(`/read-story/${id}`);
  };

  return (
    <div className={styles.storyView}>
      <div className={styles.storyHeader}>
        <div className={styles.storyTitle}>{title}</div>
        <div className={styles.storyRating}>
          <StoryRating rating={rating} />
        </div>
      </div>
      <div className={styles.storyTags}>{formatTags(tags)}</div>
      <div className={styles.storyPreview}>{preview}</div>
      <button
        className={styles.continueReadingBtn}
        onClick={handleReadFullStory}
      >
        Продовжити читати
      </button>
    </div>
  );
};

export default StoryView;
