import React from "react";
import styles from "./footer.module.scss";
import InstagramIcon from "../../../../assets/icons/instagram.svg";
import TikTokIcon from "../../../../assets/icons/tiktok.svg";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate(); // <-- use the hook

  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        <button
          className={styles.footerButton}
          onClick={() => navigate("/about-us")}
        >
          Про нас
        </button>
        <button
          className={styles.footerButton}
          onClick={() => navigate("/search-content")}
        >
          Еротичні історії
        </button>
        <button className={styles.footerButton}>Підпишись на нас</button>
      </div>
      <div className={styles.icons}>
        <a
          href="https://www.instagram.com/lasno_ua/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.iconLink}
        >
          <img src={InstagramIcon} alt="Instagram" />
        </a>
        <a
          href="https://www.tiktok.com/@lasno.world"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.iconLink}
        >
          <img src={TikTokIcon} alt="TikTok" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
