import React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import styles from "./pagination.module.scss";

const theme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "transparent",
            color: "#ffffff",
            background: "linear-gradient(135deg, #8C9FF3 0%, #9A82DE 100%)",
            borderRadius: "50%",
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "&.MuiPaginationItem-outlined": {
            borderColor: "linear-gradient(135deg, #8C9FF3 0%, #9A82DE 100%)",
            color: "white",
          },
        },
      },
    },
  },
});

interface PaginationProps {
  count: number;
  page: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  count,
  page,
  onChange,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        className={styles.centeredPagination}
        renderItem={(item) => {
          if (item.type === "start-ellipsis" || item.type === "end-ellipsis") {
            return <PaginationItem {...item} />;
          }

          if (item.type === "page") {
            return (
              <PaginationItem
                {...item}
                component="li"
                shape="rounded"
                variant="outlined"
              />
            );
          }

          if (item.type === "previous") {
            return (
              <li>
                <button
                  onClick={(e) => item.onClick(e)}
                  disabled={item.page === 1}
                  style={{ background: "transparent", border: "none" }}
                >
                  <WestRoundedIcon style={{ color: "white" }} />
                </button>
              </li>
            );
          }

          if (item.type === "next") {
            return (
              <li>
                <button
                  onClick={(e) => item.onClick(e)}
                  disabled={item.page === count}
                  style={{ background: "transparent", border: "none" }}
                >
                  <EastRoundedIcon style={{ color: "white" }} />
                </button>
              </li>
            );
          }

          return null;
        }}
      />
    </ThemeProvider>
  );
};

export default PaginationComponent;
