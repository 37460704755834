import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./app/store";
import AboutUsPage from "./bundles/common/pages/about-us/about-us.page";
import Header from "./bundles/common/components/header/header";
import Footer from "./bundles/common/components/footer/footer";
import SearchContentPage from "./bundles/common/pages/search-content/search-content.page";
import ReadStoryPage from "./bundles/common/pages/read-story/read-story.page";
import "./App.css";

function App() {
  const theme = useSelector((state: RootState) => state.theme.value);

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme]);

  return (
    <div className={`app-container ${theme}`}>
      <BrowserRouter>
        <Header />
        <div className="content-container">
          <Routes>
            <Route path="/" element={<AboutUsPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/search-content" element={<SearchContentPage />} />
            <Route path="/read-story/:storyId" element={<ReadStoryPage />} />
            {/* ... Add other Routes here as your app grows */}
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
