import React, { useEffect, useState } from "react";
import axios from "axios";
import StoryView from "../../../story-view/story-view";
import PaginationComponent from "../../components/pagination/pagination.component";
import { CircularProgress, Box } from "@mui/material";

const SearchContentPage: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state to true before fetching
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}stories?page=${page}&limit=5`
        );
        setData(response.data.data);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set loading state to false after fetching
      }
    };

    fetchData();
  }, [page]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh" // To center the CircularProgress vertically
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      {data.map((story, index) => (
        <StoryView
          key={index}
          id={story.id}
          title={story.title}
          tags={story.tags}
          rating={story.rating}
          preview={story.storyPreviewBody}
        />
      ))}

      <PaginationComponent
        count={totalPages}
        page={page}
        onChange={(event, value) => setPage(value)}
      />
    </div>
  );
};

export default SearchContentPage;
