import React from "react";
import { useLocation } from "react-router-dom";
import burgerMenuIcon from "../../../../assets/icons/burger-menu.svg";
import TemporaryDrawer from "../main-menu/main-menu";
import styles from "./header.module.scss";
import logo from "../../../../assets/icons/lasno_1.svg";

const Header: React.FC = () => {
  const location = useLocation();
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className={styles.header}>
      <img
        className={styles.burgerMenu}
        src={burgerMenuIcon}
        alt="Menu"
        onClick={toggleDrawer}
      />
      <TemporaryDrawer
        isOpen={isDrawerOpen}
        toggle={toggleDrawer}
        setDrawerOpen={setDrawerOpen}
      />
      {location.pathname !== "/about-us" && location.pathname !== "/" && (
        <img className={styles.logo} src={logo} alt="Logo" />
      )}
    </div>
  );
};

export default Header;
